<template>
	<div
		class="dropdown relative flex items-center text-left"
		@mouseover="showDropdownMenu"
		@mouseleave="hideDropdownMenu"
	>
		<div>
			<div class="targetButton" :class="[menuButtonClass]">
				<div class="flex items-center gap-2">
					<slot name="menu-button">
						<CarbarLink text="Dummy" />
					</slot>
					<div class="caret">
						<slot name="caret">
							<div
								class="ml-1 font-medium text-gray-600 duration-200"
							>
								<IconArrowDown class="text-gray-500" />
							</div>
						</slot>
					</div>
				</div>
			</div>
		</div>

		<Transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75 delay-100"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<div v-show="isOpen">
				<ul
					:class="[
						position === 'left' ? 'right-0' : 'left-0',
						zIndex,
					]"
					class="ring-none absolute mt-6 w-[230px] origin-top-right list-none rounded-md bg-white p-2.5 shadow-lg focus:outline-none"
				>
					<li v-for="(menuItem, index) in menuItems" :key="index">
						<div
							v-if="index == menuItems.length - 1"
							class="mt-4 w-full rounded bg-gray-600 py-1 text-center duration-200 hover:bg-blue-400"
						>
							<a
								:class="'block p-2 font-medium text-white duration-200'"
								:href="menuItem.href ? menuItem.href : ''"
								@click="onClickMenuItem(menuItem, $event)"
							>
								{{ menuItem.label }}
							</a>
						</div>
						<div v-else class="duration-200 hover:bg-gray-300">
							<a
								:class="'block p-3 font-medium text-gray-600 duration-200'"
								:href="menuItem.href ? menuItem.href : ''"
								@click="onClickMenuItem(menuItem, $event)"
							>
								{{ menuItem.label }}
							</a>
						</div>
					</li>
				</ul>
			</div>
		</Transition>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import CarbarLink from '@/components/links/CarbarLink.vue'
import IconArrowDown from '@/assets/svg/common/ico-arrow_down.svg'

const isOpen = ref(false)

const showDropdownMenu = () => {
	isOpen.value = true
}

const hideDropdownMenu = () => {
	isOpen.value = false
}

const props = defineProps({
	menuButtonClass: {
		type: String,
		default: '',
		required: false,
	},
	position: {
		type: String,
		default: 'left',
		required: false,
	},
	zIndex: {
		type: String,
		default: 'z-10',
		required: false,
	},
	menuItems: {
		type: Array,
		required: true,
		default: () => [],
	},
	showCaret: {
		type: Boolean,
		default: true,
		required: false,
	},
})

const emit = defineEmits(['clickMenuItem'])

const onClickMenuItem = (menuItem, e) => {
	if (!menuItem.href) {
		e.preventDefault()
	}

	if (menuItem.onclick) {
		menuItem.onclick()
	}

	emit('clickMenuItem', menuItem)
}
</script>
