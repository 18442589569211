import { useCarbarStore } from '../stores/carbar'

export default () => {
	const carbar = () => useCarbarStore()

	const toggleMobileMenu = () => {
		if (carbar().header.showMobileMenu) {
			hideMobileMenu()
			window.zE?.show()
		} else {
			showMobileMenu()
			window.zE?.hide()
		}
	}

	const hideMobileMenu = () => {
		if (!carbar().header.showMobileMenu) {
			return
		}

		carbar().header.showMobileMenu = false

		document.body.classList.remove(
			...['mobile-menu-active', 'overflow-hidden']
		)
	}

	const showMobileMenu = () => {
		if (carbar().header.showMobileMenu) {
			return
		}

		carbar().header.showMobileMenu = true

		document.body.classList.add(
			...['mobile-menu-active', 'overflow-hidden']
		)
	}

	const onMegaMenuMouseOver = (menuItem) => {
		showMegaMenu(menuItem)
	}

	const onMegaMenuMouseLeave = (menuItem) => {
		hideMegaMenu(menuItem)
	}

	const showMegaMenu = (menuItem) => {
		if (typeof menuItem.showMegaMenu === 'undefined') return

		// when force closing mega menu, bail
		if (
			menuItem.hideMegaMenuInterval &&
			menuItem.hideMegaMenuTimeout === 0
		) {
			return
		}
		menuItem.showMegaMenu = true
		menuItem.megaMenuState = 'shown'
	}

	const hideMegaMenu = (menuItem) => {
		if (typeof menuItem.showMegaMenu === 'undefined') return
		menuItem.showMegaMenu = false
		menuItem.megaMenuState = 'hidden'
	}

	const hideAllMegaMenu = (menu, excludeMenuKeys = []) => {
		menu.forEach((menuItem) => {
			if (typeof menuItem.showMegaMenu === 'undefined') return

			if (excludeMenuKeys.includes(menuItem.key)) return

			menuItem.megaMenuState = 'hidden'
			menuItem.showMegaMenu = false
		})
	}

	const onMegaMenuLinkClick = (
		megaMenu,
		type = null,
		label = null,
		category = null
	) => {
		hideMegaMenu(megaMenu, 0)
		hideMobileMenu()
		if (type !== null) {
			gtmTrack(type, label, category)
		}
	}

	const gtmTrack = (type, label, category) => {
		trackGtmEvent({
			event: 'popular_search',
			product_category: category,
			popular_search_type: type,
			popular_search_selection: label,
			engagement_location: 'Header',
		})
	}

	return {
		toggleMobileMenu,
		hideMobileMenu,
		showMobileMenu,

		onMegaMenuMouseOver,
		onMegaMenuMouseLeave,
		showMegaMenu,
		hideMegaMenu,
		hideAllMegaMenu,
		onMegaMenuLinkClick,
	}
}
