<template>
	<MegaMenu :menu="menu" menu-key="business-cars">
		<div class="container">
			<div class="flex w-full flex-row pb-4 pt-0 lg:py-12">
				<div class="order-2 hidden justify-items-end lg:flex">
					<span
						class="cursor-pointer"
						@click="hideMegaMenu(megaMenu)"
					>
						<IconTimes class="650 h-5 w-5 hover:text-blue-400" />
					</span>
				</div>

				<div class="order-1 flex w-full flex-col gap-x-10 lg:flex-row">
					<div class="w-full lg:w-1/3">
						<p
							class="mt-4 pb-3 text-base font-medium leading-none text-blue-400 md:text-lg lg:m-0"
						>
							Simplified business solution
						</p>
						<UnorderedList
							class="m-0"
							icon-gap="p-0 lg:pl-8"
							items-padding-y=""
							:show-list-icon="true"
							:items="megaMenu.megaMenu[0]"
						>
							<!-- eslint-disable vue/no-unused-vars -->
							<!-- eslint-disable vue/no-template-shadow -->
							<template
								v-for="(item, index) in megaMenu.megaMenu[0]"
								:key="index"
								#[`${_kebabCase(item.label)}`]="{ item }"
							>
								<!-- eslint-enable vue/no-unused-vars -->
								<!-- eslint-enable vue/no-template-shadow -->
								<CarbarLink
									:href="item.link"
									:class="[item.class]"
									:underline="false"
									text-color="text-gray-650"
									font-size="text-base"
									class="group w-full py-2 md:py-3"
									@click="onMegaMenuLinkClick(megaMenu)"
								>
									<span>{{ item.label }}</span>
								</CarbarLink>
							</template>
							<template #icon>
								<IconPage
									class="my-2.5 hidden h-[22px] w-[22px] text-gray-500 lg:flex"
								/>
							</template>
						</UnorderedList>
					</div>
				</div>
			</div>
		</div>
	</MegaMenu>
</template>

<script setup>
import { kebabCase as _kebabCase } from 'lodash-es'
import CarbarLink from '@/components/links/CarbarLink.vue'
import UnorderedList from '@/components/lists/UnorderedList.vue'
import MegaMenu from '@/components/headers/megamenus/MegaMenu.vue'
import headerModule from '@/modules/Header.mjs'
import IconTimes from '@/assets/svg/common/ico-times.svg'
import IconPage from '@/assets/svg/header/ico-page.svg'

const props = defineProps({
	menu: {
		type: Object,
	},
	carCount: {
		type: Number,
		default: 0,
	},
})

const { hideMegaMenu, onMegaMenuLinkClick } = headerModule()

const megaMenu = computed(() =>
	props.menu.find((menuItem) => menuItem.key === 'business-cars')
)
</script>
