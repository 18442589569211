<template>
	<Transition name="fade">
		<DefaultSection
			v-if="carbar.showCampaignStatus('free_upfront_fee')"
			v-bind="$attrs"
		>
			<component
				:is="getBannerComponent()"
				v-if="getBannerComponent()"
				v-bind="{
					'banner-title':
						campaignTag === 'blackfriday_sales'
							? campaignTitle
							: '',
					'promo-code': campaignPromoCode,
				}"
			/>
			<ZeroUpfrontFeeBanner v-else />
		</DefaultSection>
		<DefaultSection
			v-else-if="carbar.showCampaignStatus('end_of_year_sale')"
			v-bind="$attrs"
		>
			<YearEndSaleBanner />
		</DefaultSection>
		<DefaultSection
			v-else-if="carbar.showCampaignStatus('spring_sales')"
			v-bind="$attrs"
		>
			<SpringSaleBannerRefresh />
		</DefaultSection>
	</Transition>
</template>

<script setup>
import DefaultSection from '@/components/sections/DefaultSection.vue'
import HalloweenSaleBanner from '@/components/campaigns/HalloweenSaleBanner.vue'
import BlackFridayPreSaleBanner from '@/components/campaigns/BlackFridayPreSaleBanner.vue'
import YearEndSaleBanner from '@/components/campaigns/YearEndSaleBanner.vue'
import ZeroUpfrontFeeBanner from '@/components/campaigns/ZeroUpfrontFeeBanner.vue'
import SpringSaleBannerRefresh from '@/components/campaigns/SpringSaleBannerRefresh.vue'
import WorldEvDayBanner from '@/components/campaigns/WorldEvDayBanner.vue'
import { useCarbarStore } from '@/stores/carbar'

const carbar = useCarbarStore()

const campaignTag = computed(() => carbar.campaigns?.free_upfront_fee?.tag)

const getBannerComponent = () => {
	switch (campaignTag.value) {
		case 'world_ev_day_sales':
			return WorldEvDayBanner
		case 'halloween_sales':
			return HalloweenSaleBanner
		case 'blackfriday_sales':
			return BlackFridayPreSaleBanner
		default:
			return
	}
}

const campaignTitle = computed(() => carbar.campaigns?.free_upfront_fee?.badge)

const campaignPromoCode = computed(
	() => carbar.campaigns?.free_upfront_fee?.promo
)
</script>
