<template>
	<div
		class="fixed top-0 z-0 hidden h-screen w-screen bg-transparent lg:block"
		:class="[
			megaMenu.showMegaMenu
				? 'lg:z-10 lg:translate-y-0 lg:opacity-100'
				: 'lg:z-0 lg:-translate-y-full lg:opacity-0',
			megaMenu.megaMenuState === 'hidden'
				? 'lg:pointer-events-none'
				: 'lg:pointer-events-auto' ||
					  megaMenu.megaMenuState === 'hiding'
					? 'z-0'
					: 'z-10',
		]"
		@mouseover="onMegaMenuMouseLeave(megaMenu)"
	></div>
	<div
		class="w-full transform transition duration-300 lg:absolute lg:top-0 lg:flex lg:bg-white lg:shadow-lg"
		:class="[
			megaMenu.showMegaMenu
				? 'lg:z-10 lg:translate-y-0 lg:opacity-100'
				: 'lg:z-0 lg:-translate-y-full lg:opacity-0',
			megaMenu.megaMenuState === 'hidden'
				? 'lg:pointer-events-none'
				: 'lg:pointer-events-auto' ||
					  megaMenu.megaMenuState === 'hiding'
					? 'z-0'
					: 'z-10',
		]"
		@mousemove="onMegaMenuMouseOver(megaMenu)"
	>
		<slot />
	</div>
</template>

<script setup>
import { computed } from 'vue'
import headerModule from '@/modules/Header.mjs'

const props = defineProps({
	menuKey: {
		type: String,
		required: true,
	},
	menu: {
		type: Object,
	},
})

const { onMegaMenuMouseOver, onMegaMenuMouseLeave } = headerModule()

const megaMenu = computed(() =>
	props.menu.find((item) => item.key === props.menuKey)
)
</script>
