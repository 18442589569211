<template>
	<div
		class="fixed top-0 z-[1000] flex h-12 w-full flex-row items-center justify-center p-4 md:h-8"
		:class="bgColor"
	>
		<p class="text-center text-base text-white">
			Limited-Time Offer: Get a $3,000 Incentive with Tesla!* -&nbsp;
			<CarbarLink
				text="Find out more"
				:text-color="textColor"
				:underline-color="underlineColor"
				font-size="text-base"
				class="cursor-pointer"
				@click="goToPromo('/business/novated-lease')"
			/>
		</p>
	</div>
</template>

<script setup>
import CarbarLink from '@/components/links/CarbarLink.vue'

defineProps({
	bgColor: {
		type: String,
		default: 'bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300',
		required: false,
	},
	textColor: {
		type: String,
		default: 'text-white hover:text-gray-300',
		required: false,
	},
	underlineColor: {
		type: String,
		default: 'decoration-white hover:decoration-gray-300',
		required: false,
	},
})

const router = useRouter()
const route = useRoute()

const goToPromo = async (url) => {
	if (route.path !== url) {
		await router.push(url)
		nextTick(() => {
			scrollTo('#promo')
		})
	} else {
		scrollTo('#promo')
	}
}
</script>
