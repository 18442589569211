<template>
	<div>
		<!-- Login Signup -->
		<div class="user-dashboard mt-1 px-4">
			<div
				class="flex flex-col items-center justify-center gap-y-4 rounded bg-gray-50 p-4"
			>
				<div v-if="isLoggedIn()" class="w-full">
					<div class="mb-6 flex w-full items-center justify-between">
						<div class="pl-1">
							<span class="text-xl font-semibold text-gray-700">
								Welcome back, {{ auUser.firstName }}.
							</span>
						</div>
						<div v-if="auUser.user" class="pr-1">
							<UserAccountIcon :is-novated="auUser.isNovated" />
						</div>
					</div>
					<div class="w-full">
						<ul
							class="m-0 flex list-none flex-col gap-y-5 lg:space-x-5"
						>
							<li
								v-for="(userMenuItem, index) in userMenu"
								:key="index"
							>
								<!-- Replace Last Item Style - Logout -->
								<div
									v-if="index == userMenu.length - 1"
									class="mt-2 w-full rounded bg-gray-600 p-4 text-center"
								>
									<a
										class="font-medium text-white"
										:href="
											userMenuItem.href
												? userMenuItem.href
												: ''
										"
										@click="
											onClickMenuItem(
												userMenuItem,
												$event
											)
										"
									>
										{{ userMenuItem.label }}
									</a>
								</div>
								<div v-else>
									<a
										class="px-1 font-medium text-gray-600"
										:href="
											userMenuItem.href
												? userMenuItem.href
												: ''
										"
										@click="
											onClickMenuItem(
												userMenuItem,
												$event
											)
										"
									>
										{{ userMenuItem.label }}
									</a>
								</div>
							</li>
						</ul>
					</div>
				</div>

				<div
					v-if="!isLoggedIn()"
					class="flex w-full items-center justify-center"
				>
					<CarbarLink
						:href="`/sign-up#${
							carbar.header.signUpUserType === 'business'
								? 'corporate'
								: 'personal'
						}`"
						:underline="false"
						class="flex w-full items-center"
						@click="signupClickHandler()"
					>
						<CarbarButton
							:background-color="'bg-blue-400'"
							:min-width="'w-full'"
						>
							Join for free
						</CarbarButton>
					</CarbarLink>
				</div>
				<div
					v-if="!isLoggedIn()"
					class="flex w-full items-center justify-center"
				>
					<CarbarLink
						:href="'/secure/login'"
						:underline="false"
						class="flex w-full justify-center rounded border py-3 text-center"
						@click="login()"
					>
						<IconButton
							:min-width="`min-w-full`"
							class="hidden lg:flex"
						>
							<template #icon>
								<IconUserProfile class="text-gray-500" />
							</template>
						</IconButton>
						<span class="flex text-base lg:hidden">Sign in</span>
					</CarbarLink>
				</div>
			</div>
		</div>

		<ul class="mb-20 mt-0 flex list-none flex-col space-y-4 p-4">
			<li
				v-for="(menuItem, index) in menu"
				:key="menuItem.label"
				class="flex"
			>
				<SlideDownAccordion
					class="header-mobile w-full"
					:class="'accordionMobile' + index"
				>
					<template #disclosure-button="{ open }">
						<div
							class="container"
							:class="[open ? '' : '']"
							@click="toggleOpenState(index)"
						>
							<div class="flex w-full justify-between pt-6">
								<h4
									class="font-body text-xl font-medium"
									:class="[
										open
											? 'text-blue-400'
											: 'text-gray-700',
									]"
								>
									{{ menuItem.label }}
								</h4>
								<div class="flex items-center justify-center">
									<div
										class="caret"
										:class="open ? 'open' : ''"
									>
										<div
											class="on-open-rotate-180 font-medium text-gray-600 duration-200"
										>
											<IconArrowDown
												class="text-gray-600"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #disclosure-panel="{ open }">
						<div :class="[open ? 'rounded-b bg-gray-50' : '']">
							<PersonalMegaMenu
								v-if="menuItem.key === 'personal-cars'"
								:car-count="carbar.vehicleCount.subscription"
								:menu="menu"
							/>
							<BusinessMegaMenu
								v-if="menuItem.key === 'business-cars'"
								:menu="menu"
							/>
							<SupportMegaMenu
								v-if="menuItem.key === 'support'"
								:menu="menu"
							/>
						</div>
					</template>
				</SlideDownAccordion>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import CarbarLink from '@/components/links/CarbarLink.vue'
import CarbarButton from '@/components/buttons/CarbarButton.vue'
import IconButton from '@/components/buttons/IconButton.vue'
import { useCarbarStore } from '@/stores/carbar'
import { useCarbarAuAuthStore } from '@/stores/carbar-au-auth'
import PersonalMegaMenu from '@/components/headers/megamenus/PersonalMegaMenu.vue'
import BusinessMegaMenu from '@/components/headers/megamenus/BusinessMegaMenu.vue'
import SupportMegaMenu from '@/components/headers/megamenus/SupportMegaMenu.vue'
import SlideDownAccordion from '@/components/accordions/SlideDownAccordion.vue'
import headerModule from '@/modules/Header.mjs'
import IconArrowDown from '@/assets/svg/common/ico-arrow_down.svg'
import IconUserProfile from '@/assets/svg/common/ico-user_profile.svg'
import UserAccountIcon from '@/components/headers/UserAccountIcon.vue'

const carbar = useCarbarStore()
const auUser = useCarbarAuAuthStore()

const props = defineProps({
	menu: {
		type: Object,
	},
})

const emits = defineEmits(['login', 'join', 'clickMenuItem'])

const { toggleMobileMenu } = headerModule()

const onClickMenuItem = (menuItem, e) => {
	if (!menuItem.href) {
		e.preventDefault()
	}

	if (menuItem.onclick) {
		menuItem.onclick()
	}

	emits('clickMenuItem', menuItem)
}

const userMenu = ref([
	{
		label: 'Dashboard',
		href: `/account`,
	},
	{
		label: 'Account',
		href: `/account/settings`,
	},
	{
		label: 'Orders',
		href: `/account/orders`,
	},
	{
		label: 'Help & support',
		href: `https://support.carbar.com.au`,
	},
	{
		label: 'Logout',
		onclick: () => {
			signOut()
		},
	},
])

const accordionArray = ref([
	{
		id: 0,
		isOpened: false,
	},
	{
		id: 1,
		isOpened: false,
	},
	{
		id: 2,
		isOpened: false,
	},
])

const filteredAccordion = ref([])

const toggleOpenState = (e) => {
	// Toggle and update array.
	const currentState = accordionArray.value[e].isOpened
	accordionArray.value[e].isOpened = !currentState

	const userDashboard = document.querySelector('.user-dashboard')
	const userDashboardHeight = userDashboard.offsetHeight

	// Only scroll menu Y position if first tab is opened and user selected second tab.
	const accordionList = document.querySelector('.mobile-menu')
	if (accordionArray.value[0].isOpened === true && e === 1) {
		accordionList.scrollTo({
			top: userDashboardHeight + 20,
			behavior: 'smooth',
		})
	}

	// Filter accordion elements that are not selected.
	filteredAccordion.value = accordionArray.value.filter(
		(el) => el.id !== e && el.isOpened === true
	)

	// Loop through accordion elements that are not selected.
	// Check their isOpened state is true.
	// If true, close them.
	filteredAccordion.value.forEach((element) => {
		if (element.isOpened === true) {
			const buttonId = element.id
			const accordionButton = document.querySelector(
				`.accordionMobile${buttonId}> button`
			)
			accordionButton.click()

			// Toggle and update this array element's state.
			accordionArray.value[buttonId].isOpened =
				!accordionArray.value[buttonId].isOpened
		}
	})
}

const signupClickHandler = (e) => {
	toggleMobileMenu()

	// This function is used only to refresh page if user click on sign-up link/button from sign-up page
	e.preventDefault()
	if (route.path === '/sign-up') {
		window.location.reload()
	}
}

const login = () => {
	toggleMobileMenu()
	emits('login')
}
</script>
